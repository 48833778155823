@import '~styles/mixins.sass';

.popup-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 45;
	@include display-flex-center-mixin; }

.popup-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	@include display-flex-center-mixin; }

.popup-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.event-alert-container {
	width: 315px;
	height: 334px;
	position: absolute;
	top: 10px;
	right: 40px;
 }	// z-index: 42

.event-alert-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	overflow: hidden; }

.event-alert-content {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	flex-direction: column; }

.event-alert-image {
	width: 174px;
	height: 151px;
	margin: 20px 0; }

.event-alert-message1 {
	padding: 0 10px 5px 10px;
	word-break: break-all;
	text-align: center; }

.event-alert-message2 {
	text-align: center;
	word-break: break-all;
	padding: 0 10px 10px 10px; }

.event-alert-opensin-message {
	padding-bottom: 15px; }

.event-alert-button-container {
	position: absolute;
	width: 100%;
	height: 35px;
	bottom: -18px;
	left: 0;
	@include display-flex-center-mixin; }

.event-alert-open-button {
	padding: 5px 10px;
	background: white;
	border-radius: 18px;
	overflow: hidden;
	@include display-flex-center-mixin; }

.padding-ceo {
	padding: 24px;
	box-sizing: border-box; }

.ceo-message-heading {
	width: 100%;
	text-align: center;
	margin-bottom: 10px; }

.ceo-container {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px; }

.ceo-details {
	width: calc(100% - 80px);
	height: 100%;
	box-sizing: border-box;
	padding-right: 10px; }

.ceo-event-image {
	width: 80px;
	height: 100%; }

.ceo-tag-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.ceo-image-container {
	margin-right: 10px; }

.ceo-image {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	overflow: hidden; }

.ceo-details-container {
	padding-left: 2px; }

.ceo-name {
	padding-bottom: 1px; }

.ceo-designation {
	padding-bottom: 1px; }

.ceo-event-message-content {
	height: 65px;
	width: 100%;
	text-align: left;
	box-sizing: border-box;
	margin: 20px 0;
	overflow: hidden; }
