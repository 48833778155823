@import '~styles/mixins.sass';

.recommendation-container {
	width: 100%;
	cursor: pointer;
	position: relative;
	min-height: 50px; }

.single-recommendation {
	width: 100%;
	// height: 33px
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	box-sizing: border-box;
	padding: 5px 5px; }

.rec-image {
	margin-top: 3px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	overflow: hidden; }

.rec-content {
	box-sizing: border-box;
	padding: 0 7px;
	width: calc(100% - 32px);
	text-align: left; }

.rec-talk-image {
	width: 12px;
	height: 10px; }

.all-recs {
	width: 100%; }
