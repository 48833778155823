@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.button-style {
    @extend %box-sizing;
    @include display-flex-center-mixin;
    min-width: 120px;
    width: fit-content;
    cursor: pointer;
    padding: 5px 20px;
    height: 36px;
    text-transform: uppercase;
    display: table;
    text-align: center; }
