@import '~styles/mixins.sass';

.popup-timer-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.95);
	z-index: 30; }

.popup-timer-border {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 5px; }
