@import '~styles/mixins.sass';

.iframe-container {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 100;
	box-sizing: border-box;
	background: white;
	overflow: hidden; }

.iframe-header {
	width: 100%;
	height: 53px;
	box-sizing: border-box;
	padding: 0 30px;
	@include display-flex-center-mixin; }

.iframe-heading {
	width: 60%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center; }

.iframe-header-download-button {
	width: 20%;
	height: 100%;
	@include display-flex-center-mixin; }

.iframe-header-close {
	width: 20%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center; }

.close-button {
	width: 35px;
	height: 35px;
	margin-left: 5px; }

.iframe-content {
	width: 100%;
	height: calc(100% - 55px);
	box-sizing: border-box; }

.iframe-loading-container {
	width: 100%;
	height: calc(100% - 40px);
	box-sizing: border-box;
	@include display-flex-center-mixin; }

iframe {
	border: none;
	margin: none; }

.header-border {
	width: 100%;
	height: 2px; }
