@import '~styles/mixins.sass';

.popup-container {
	width: 795px;
	min-height: 650px;
	max-height: 700px;
	position: relative; }

.border-container {
	width: 100%;
	height: 5px;
	@include display-flex-center-mixin; }

.heading-container {
	width: 100%;
	padding: 13px 55px 0 55px;
	box-sizing: border-box; }

.heading-main {
	padding-bottom: 4px; }

.heading-desc {
	padding-bottom: 16px; }

.heading-tags {
	padding-bottom: 6px; }

.heading-recs {
	padding-bottom: 2px; }

.buttons-container {
	@include display-flex-center-mixin;
	width: 100%;
	padding: 15px 0;
	flex-direction: column; }

.action-button {
	padding: 0 10px; }

.action-button-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center; }

.dfv-card-container {
	width: 100%;
	box-sizing: border-box;
	min-height: 317px;
	padding: 15px 20px; }

.dfv-test-card-container {
	width: 100%;
	box-sizing: border-box;
	min-height: 345px; }

.dfv-card {
	width: 100%;
	height: 100%;
	min-height: 317px;
	position: relative;
	box-sizing: border-box; }

.double-quotes-top {
	width: 22px;
	height: 14px;
	position: absolute;
	left: 25px;
	top: -8px; }

.double-quotes-bottom {
	width: 22px;
	height: 14px;
	position: absolute;
	right: 22px;
	bottom: -8px; }

.not-unlocked-container {
	width: 100%;
	height: 100%;
	min-height: 315px;
	@include display-flex-center-mixin;
	flex-direction: column;
	padding: 0 73px;
	box-sizing: border-box; }

.not-unlocked-image {
	width: 224px;
	height: 160px; }

.not-unlocked-desc {
	padding-top: 18px; }

.unlock-button {
	padding-top: 12px;
	@include display-flex-center-mixin;
	flex-direction: column; }

.dfv-feedback-container {
	width: 100%;
	height: 315px; }

.dfv-container {
	width: 100%;
	height: 67px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.feedback-container {
	width: 100%;
	height: calc(100% - 67px);
	min-height: 248px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.team-feedback-container {
	width: 50%;
	height: 100%;
	box-sizing: border-box;
	overflow-y: auto; }

.user-feedback-container {
	width: 50%;
	height: 100%;
	box-sizing: border-box;
	overflow-y: auto; }

.feedback-heading {
	width: 100%;
	box-sizing: border-box;
	padding: 12px 33px 5px 33px;
	text-align: left; }

.refine-idea-container {
	width: 100%;
	padding: 0 33px 20px 33px;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	flex-direction: column; }

.refine-idea-desc {
	padding-top: 10px; }

.refine-idea-button {
	padding-top: 5px;
	@include display-flex-center-mixin;
	flex-direction: column; }

.shortlist-desc {
	padding-bottom: 10px; }

.refined-tag-container {
	width: 100%;
	padding: 20px 55px 0 55px;
	box-sizing: border-box; }

.refined-tag-content {
	width: fit-content;
	display: table;
	padding: 0px 18px; }

.refined-image {
	position: absolute;
	top: 5px;
	left: 0;
	width: 79px;
	height: 75px; }

.close-button {
	position: absolute;
	right: -12px;
	top: -12px;
	width: 24px;
	height: 24px;
	z-index: 2; }

.response-header-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 0 7px; }

.response-image {
	width: 34px;
	height: 34px;
	overflow: hidden;
	border-radius: 50%; }

.response-text-score {
	padding-left: 10px; }

.response-score-change {
	padding-left: 2px; }

.team-score-header {
	display: flex;
	width: 100%;
	justify-content: space-around;
	box-sizing: border-box;
	padding: 0 5px; }

.persona-score-header {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 0 10px; }

.team-response-container {
	width: 100%;
	box-sizing: border-box;
	padding: 5px 27px; }

.persona-response-container {
	width: 100%;
	box-sizing: border-box;
	padding: 0 20px 10px 60px; }

.tab-container {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	padding-left: 40px;
	box-sizing: border-box; }

.each-tab {
	padding: 0 10px;
	margin: 0 10px; }

.tab-border {
	width: 100%;
	height: 2px;
	opacity: 0.5;
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	background-image: linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd); }

.test-report-container {
	width: 100%;
	height: 340px;
	padding: 0px 60px 10px 60px;
	box-sizing: border-box;
	background-color: #fbf5ff;
	overflow-y: auto; }

.test-report-content {
	border-radius: 5px;
	width: 100%;
	height: calc(100% - 67px);
	display: flex;
	flex-wrap: wrap;
	background: white; }

.test-report-container-loading {
	width: 100%;
	height: 315px;
	padding: 0 73px;
	box-sizing: border-box;
	background-color: #ffffff;
	@include display-flex-center-mixin; }

.each-quadrant-container {
	width: 50%;
	height: 50%;
	box-sizing: border-box; }

.each-quadrant-heading {
	width: 100%;
	box-sizing: border-box;
	padding: 10px 0 10px 0;
	text-align: center;
	height: 35px; }

.each-quadrant-response-content {
	padding: 0 20px;
	box-sizing: border-box;
	height: calc(100% - 35px);
	width: 100%;
	overflow-y: auto; }

.each-response-container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	padding-bottom: 5px; }

.each-response-image {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	overflow: hidden; }

.each-response-content {
	padding-left: 10px;
	box-sizing: border-box;
	text-align: left;
	width: calc(100% - 24px); }

.column-flex-center {
	@include display-flex-center-mixin;
	flex-direction: column; }
