@import '~styles/mixins.sass';

.header-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@extend %row; }

.logo-section {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	width: 7%;
	height: 100%; }

.logo-container {
	width: 100%;
	height: 70%;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.logo-image {
	height: 30px;
	width: 30px;
	overflow: hidden; }

.logo-name {
	padding-left: 15px; }

.other-section {
	width: 93%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.individual-header-component {
	height: 100%;
	@include display-flex-center-mixin; }

.individual-item-container {
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	position: relative; }

.individual-item-content {
	height: 70%;
	max-height: 31px;
	padding: 0 15px;
	@include display-flex-center-mixin; }

.individual-item-image {
	height: 100%;
	max-height: 24px;
	overflow: hidden;
	@include display-flex-center-mixin; }

.individual-item-name {
	padding: 0 15px; }

.individual-item-downArrow {
	height: 100%;
	max-height: 8px;
	overflow: hidden;
	@include display-flex-center-mixin; }


.quarters-container {
	display: flex;
	align-items: flex-end;
	height: 100%;
	box-sizing: border-box;
	padding-top: 5px; }

.single-quarter-container {
	display: flex;
	align-items: center;
	height: 100%;
	position: relative; }

.single-quarter-container-background {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0; }

.quarter-text {
	padding-right: 5px;
	padding-left: 7px;
	z-index: 1; }

.quarter-analytics {
	width: 22px;
	height: 13px;
	// z-index: 1
	margin-right: 7px;
	position: relative; }

.metrics-container {
	display: flex;
	align-items: center;
	height: 100%; }

.single-metric-container {
	display: flex;
	align-items: center;
	height: 100%;
	padding-right: 18px; }

.metric-text-container {
	display: flex;
	align-items: top;
	padding-right: 10px; }

.metric-text {
	display: flex;
	height: 100%; }

.metric-value {
	padding-left: 1px;
	position: relative;
	@include display-flex-center-mixin; }

.knolskape-container {
	display: flex;
	align-items: center;
	height: 100%; }

.knolskape-image {
	width: 100px;
	height: 18px;
	cursor: pointer; }

.user-profile-container-with-options {
	@include display-flex-center-mixin;
	position: relative;
	outline: none; }

.user-profile-container {
	@include display-flex-center-mixin;
	// width: 40px
	cursor: pointer; }

.user-profile-image-container {
	width: 24px;
	height: 24px; }

.user-profile-arrow-container {
	margin-left: 5px;
	width: 8px;
	height: 6px; }

.user-profile-option-container {
	position: absolute;
	width: 280px;
	height: 110px;
	z-index: 50;
	top: -115px;
	left: -230px;
	overflow: hidden; }

.each-option {
	width: 100%;
	padding: 5px 20px;
	box-sizing: border-box; }

.user-name-container {
	width: 100%;
	box-sizing: border-box;
	padding: 5px 20px; }

.user-name {
	width: 100%;
	text-align: left;
	padding: 5px 0; }
