@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.popup-container {
    @extend %row;
    @extend %box-sizing;
    @include display-flex-column;
    height: 100%;
    padding-bottom: 15px; }

.popup-header-container {
    @extend %width-100;
    @include display-flex;
    height: 72px; }

.popup-image-title-container {
    @include display-flex-start;
    width: 80%;
    height: 100%; }

.popup-image {
    width: 78px;
    height: 56px;
    padding-right: 26px; }

.popup-header-title {
    text-transform: uppercase;
    position: relative; }

.title-underline-container {
    position: absolute;
    width: 110%;
    height: 20px;
    bottom: -4px;
    left: 0px; }

.popup-questions-map-container {
    @extend %row;
    @include display-flex;
    height: calc(100% - 72px);
    padding: 0; }

.popup-questions-container {
    @extend %col-sm-7;
    @extend %col-md-7;
    @extend %col-lg-7;
    height: 100%;
    padding-left: 0;
    position: relative; }

.popup-questions-content {
    @extend %width-100;
    @extend %box-sizing;
    height: 100%;
    padding: 10px; }

.popup-chat-container-empty {
    @extend %width-100;
    @include display-flex-center-mixin;
    flex-direction: column;
    height: 51%; }

.popup-chat-container-text {
    @extend %width-100;
    @extend %box-sizing;
    height: 51%;
    padding: 5px 0 10px 0; }

.pop-up-chat-container {
    @extend %width-100;
    height: 100%;
    overflow-y: auto; }

.popup-ask-questions-container {
    @extend %width-100;
    @extend %box-sizing;
    height: 49%;
    padding: 18px 0 12px 12px; }

.ask-questions-title-container {
    @include display-flex;
    height: 61px;
    position: relative; }

.ask-questions-title-cost {
    @include display-flex;
    @extend %box-sizing;
    padding-top: 5px;
    padding-right: 12px;
    width: 50%;
    height: 100%; }

.display-to-start {
    @include display-flex;
    justify-content: flex-start;
    align-items: flex-start; }

.display-to-end {
    @include display-flex;
    justify-content: flex-end;
    align-items: flex-start; }

.ask-questions-chatbox-container {
    @extend %width-100;
    @include display-flex-space-between;
    flex-wrap: wrap;
    height: calc(100% - 61px);
    overflow-y: auto; }

.popup-empathy-map-container {
    @extend %col-sm-5;
    @extend %col-md-5;
    @extend %col-lg-5;
    height: 100%; }

.ask-question-container {
    padding-top: 10px; }

.question-response-container {
    @extend %width-100;
    margin-bottom: 5px; }

.align-flex-right {
    @include display-flex-end; }

.align-flex-right-column {
    @include display-flex;
    flex-direction: column;
    align-items: flex-end; }

.response-tick-container {
    @extend %width-100;
    @include display-flex; }

.align-flex-left {
    @include display-flex-start;
    align-items: flex-start;
    flex-direction: column; }

.cursor-pointer {
    cursor: pointer; }

.action-tick-container {
    width: 34px;
    height: 32px;
    margin-left: 2px;
    position: relative;
    @include display-flex-center-mixin; }

.action-tick {
    width: 16px;
    height: 16px; }

.action-tick-move {
    width: 34px;
    height: 32px; }

.empathy-initial-intro-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%; }

.button-container {
    width: 100%;
    padding-top: 7px;
    padding-left: 20px;
    box-sizing: border-box; }

.tooltip-container-right {
    width: 250px;
    position: absolute;
    top: -5px;
    left: 20px;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: flex-start; }

.tooltip-arrow-left-right {
	width: 0;
	height: 0;
	margin-top: 8px; }

.tooltip-arrow-top-bottom {
	width: 0;
	height: 0; }

.tooltip-content {
    box-sizing: border-box;
    padding: 7px;
    width: calc(100% - 7px);
    // display: flex
    // justify-content: flex-start
    // align-items: center
    text-align: left; }


