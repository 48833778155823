@import '~styles/mixins.sass';

.change-value-container {
	@include display-flex-center-mixin;
	height: 22px;
	padding: 0 15px;
	border-radius: 20px;
	position: absolute;
	z-index: 11; }

.arrow {
	width: 7px;
	height: 5px;
	margin-right: 4px; }

.value-text {
	padding-left: 3px; }
