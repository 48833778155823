@import '~styles/mixins.sass';

.container {
	width: 100%;
	height: 100%;
	@include display-flex-column;
	padding-left: 14px;
	padding-right: 14px;
	padding-top: 16px;
	box-sizing: border-box; }

.title {
	padding-left: 18px; }

.content {
	height: calc(100% - 58px);
	@include display-flex;
	justify-content: flex-start;
	flex-direction: column;
	box-sizing: border-box; }

.image {
	width: 90%;
	padding-bottom: 10px; }

.idea-card-content {
	margin-top: 18px;
	width: 100%;
	padding: 0 16px 14px 16px;
	box-sizing: border-box;
	position: relative;
	margin-bottom: 65px; }

.idea-card-description {
	width: 100%;
	padding-bottom: 3px;
	text-align: left; }

.dfv-value-content {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0 15px 0;
	box-sizing: border-box; }

.dfv-prototype-container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding-top: 10px;
	box-sizing: border-box; }

.prototype-image {
	width: 25px;
	height: 34px;
	box-sizing: border-box; }

.dfv-value-content-prototype {
	box-sizing: border-box;
	padding-left: 10px;
	padding-top: 5px; }

.revenue-cs-container {
	@include display-flex;
	justify-content: space-between; }

.revenue-container {
	padding-top: 5px;
	width: 50%;
	@include display-flex-center-mixin;
	justify-content: flex-start; }

.flex-end {
	justify-content: flex-end; }

.up-arrow {
	margin: 0 5px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent; }

.dfv-value-content {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0 15px 0;
	box-sizing: border-box; }

.tick-container {
	width: 19px;
	height: 19px;
	position: absolute;
	top: -13px;
	right: -5px; }

.unlock-dfv-content {
	width: 100%;
	text-align: left;
	padding-bottom: 15px; }

.no-ideas-image {
	width: 178px;
	height: 118px; }

.no-idea-card-content {
	width: 100%;
	padding-top: 50px;
	@include display-flex-center-mixin; }

.right-side-tag {
	position: absolute;
	right: -250px;
	top: -5px;
	width: 300px;
	height: 122px;
	z-index: 2; }

.bottom-side-tag {
	position: absolute;
	left: 5px;
	bottom: -50px;
	width: 100px;
	height: 50px;
	z-index: 2; }

.background-tag-container {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin; }

.tag-content-container {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin; }

.tag-content-container-content-right {
	@include display-flex-center-mixin;
	width: 100%;
	height: 100%;
	transform: rotate(12deg) {
    transform-origin: 50% 50%; }
	box-sizing: border-box;
	padding-left: 20px; }

.tag-content-container-content-bottom {
	@include display-flex-center-mixin;
	width: 100%;
	height: 100%;
	// transform: rotate(-6deg)
 // // transform-origin: 50% 50%
	box-sizing: border-box;
	padding-left: 20px; }

.single-cost-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	margin-left: 10px; }

.single-cost-heading {
	padding-bottom: 1px; }

.single-cost-value {
	padding-bottom: 1px; }
