@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.arrow-text-container {
    box-sizing: border-box;
    padding-left: 6px;
 }    // @include display-flex

.walkthrough-arrow {
    width: 30px; }

.arrow-text-container-flex {
    box-sizing: border-box;
    padding-left: 40px;
    padding-top: 4px;
    @include display-flex; }

.text {
    width: 190px;
    padding-left: 10px; }
