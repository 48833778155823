@import '~styles/mixins.sass';

.footer-container {
	@include display-flex-center-mixin;
	flex-direction: column;
	width: 100%;
	height: 43px;
	min-width: 1024px; }

.footer-border {
	height: 3px;
	width: 100%;
	opacity: 0.5;
	backdrop-filter: blur(10px);
	background-image: linear-gradient(to right, #e458fc, #1d1279 27%, #fc5445 47%, #473af5 68%, #47f2fd); }

.hidden {
	display: none; }
