@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.execute-popup-container {
    width: 1153px;
    height: 640px;
    position: relative;
    display: flex; }

.left-images-execute-container {
    width:  46px;
    height: 215px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform-origin: 50% 50%;
    transform: rotateY(180deg); }

.right-images-execute-container {
    width:  76px;
    height: 94px;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    transform: rotateY(180deg); }

.popup-header {
    width: 373px;
    height: 100%;
    @extend %box-sizing;
    position: relative; }

.popup-header-background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @extend %box-sizing; }

.popup-header-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @extend %box-sizing;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 66px; }

.header-image {
    width: 303px;
    height: 210px;
    margin-bottom: 49px; }

.header-left-image {
    width: 94px;
    height: 100%; }

.header-right-image {
    width: 229px;
    height: 100%; }

.header-content {
    width: 100%;
    @extend %box-sizing; }

.title {
    @extend %width-100;
    @extend %box-sizing;
    padding: 0 12px 10px 12px;
    text-align: center; }

.desc-container {
    padding-top: 9px; }

.header-border {
    @extend %width-100;
    @extend %box-sizing;
    height: 3px; }

.popup-content-container {
    width: calc(100% - 373px);
    height: 100%;
    @include display-flex-center-mixin;
    position: relative; }

.popup-content-container-background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.popup-content-container-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 20px; }

.cost-container {
    width: 80%;
    padding: 0 60px 48px 60px;
    text-align: center;
    box-sizing: border-box; }

.budget-cost-container {
    width: 100%;
    text-align: center; }

.close-icon {
    position: absolute;
    right: -13px;
    top: -13px;
    width: 28px;
    height: 28px;
    z-index: 1; }

.content-container-main {
    width: 100%;
    height: calc(100% - 105px);
    box-sizing: border-box;
    padding: 0px 10px 10px 10px;
    display: flex; }

.execution-list-container {
    width: 320px;
    height: 100%;
    padding-right: 10px;
    box-sizing: border-box; }

.cost-budget-container {
    width: calc(100% - 320px);
    height: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column; }

.cost-budget-image {
    width: 179px;
    height: 110px;
    margin: 10px 0 20px 0; }

.costs {
    margin-bottom: 20px; }

.main-heading {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }

.execution-content {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto; }

.cost-budget-content {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    flex-direction: column; }

.content-container-footer {
    width: 100%;
    height: 105px;
    box-sizing: border-box;
    @include display-flex-center-mixin;
    flex-direction: column; }

.footer-desc {
    width: 100%;
    text-align: center;
    margin-bottom: 14px; }

.buttons-container {
    width: 100%;
    height: 35px;
    @include display-flex-center-mixin; }

.execution-help-text-container {
    width: 385px;
    height: 133px;
    position: relative; }

.execution-help-text-background {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0; }

.execution-help-text-content {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    @include display-flex-center-mixin; }

.execution-help-text-top-image {
    width: 34px;
    height: 34px;
    z-index: 1;
    top: 0;
    left: 0;
    position: absolute; }

.execution-help-text {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
    text-align: left; }

.execution-count-help-text {
    width: 100%;
    height: 140px;
    position: relative;
    @include display-flex-center-mixin; }

.execution-count-help-text-background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0; }

.execution-count-help-text-content {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 20px;
    text-align: center; }
