@import '~styles/mixins.sass';

.change-value-container {
	@include display-flex-center-mixin;
	width: 32px;
	height: 23px;
	border-radius: 11.5px;
	margin-left: 7px; }

.arrow {
	width: 5px;
	height: 4px; }

.value-text {
	padding-left: 3px; }
