@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.button-style {
    @extend %box-sizing;
    @include display-flex-center-mixin;
    cursor: pointer;
    height: 34px;
    text-transform: uppercase;
    position: relative;
    width: fit-content;
    padding: 5px 30px; }

.hollow-background {
    position: absolute;
    z-index: 1;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    @include display-flex-center-mixin;
    top: 2px;
    left: 2px;
    border-radius: 50px; }

.hollow-button {
    @extend %box-sizing;
    height: calc(100% - 4px);
    width: max-content;
    @include display-flex-center-mixin;
    z-index: 2; }

.table-display {
    display: table; }
