@import '~styles/mixins.sass';

.container {
	height: 99%;
	width: 491px;
	z-index: 10;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: relative; }

.event-background {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end; }

.event-background-image {
	width: 181px;
	height: 123px;
	margin-right: 10px;
	margin-bottom: 10px; }

.empty-event-container {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	flex-direction: column; }

.empty-event-image {
	width: 178px;
	height: 118px;
	opacity: 0.7; }

.empty-event-text {
	padding-top: 10px; }

.event-content {
	width: 100%;
	height: 100%;
	z-index: 2;
	top: 0;
	left: 0; }

.header {
	height: 75px;
	padding-top: 9px;
	padding-bottom: 9px;
	box-sizing: border-box;
	display: flex;
	align-items: center; }

.content {
	height: calc(100% - 75px);
	padding: 26px;
	box-sizing: border-box;
	overflow-y: auto; }

.team-chat {
	padding-left: 20px;
	width: 76px;
	height: 58px; }

.close {
	width: 28px;
	height: 28px;
	cursor: pointer; }

.title-close {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 23px;
	padding-right: 25px; }

.image {
	width: 100%;
	height: 100%; }

.message-bubble {
	min-height: 50px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 16px 20px 16px 20px;
	box-sizing: border-box;
	// border-radius: 10px
	margin-bottom: 10px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px; }

.message-consecutive-bubble {
	min-height: 50px;
	display: flex;
	align-items: center;
	padding: 16px 20px 16px 20px;
	box-sizing: border-box;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-bottom: 10px;
	margin-top: 5px; }

.chat-messages {
	display: flex;
	box-sizing: border-box;
	margin-bottom: 10px; }

.chat-member-image {
	width: 34px;
	height: 34px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 15px; }

.chat-container {
	position: relative;
	width: calc(100% - 50px); }

.arrow-tip {
	width: 0;
	height: 0;
	// border-top: 15px solid white
	border-left: 15px solid transparent;
	position: absolute;
	top: 5px;
	left: -10px; }

.chat-container-event {
	position: relative;
	width: 340px;
	margin-left: 50px; }

.arrow-tip-event {
	width: 0;
	height: 0;
	border-top: 15px solid white;
	border-left: 15px solid transparent;
	position: absolute;
	top: 0px;
	left: -10px; }

.single-line {
	width: 100%;
	height: 1px;
	margin: 40px 0; }

.single-impact {
	width: 100%;
	@include display-flex-center-mixin; }

.left {
	width: 52%;
	text-align: right; }

.center {
	padding: 0 7px; }

.right {
	width: calc(45% - 10px);
	text-align: left;
	display: flex;
	align-items: center; }

.right-arrow {
	width: 6px;
	height: 4px;
	margin-right: 5px; }

.event-chat-text {
	padding-top: 3px;
	padding-left: 7px; }
