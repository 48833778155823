@import '~styles/mixins.sass';

.container {
	height: 531px;
	width: 795px;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 1px 1px 5px 0 #d5d8e2;
	background-color: #ffffff;
	position: relative; }

.title {
	height: 24px;
	padding: 32px 52px;
	width: 100%;
	box-sizing: border-box; }

.content {
	padding: 0 52px;
	box-sizing: border-box;
	height: calc(100% - 77px);
	overflow-y: auto;
	margin-top: 8px; }

.helper-text {
	min-height: 28px; }

.personas {
	margin-top: 8px; }

.persona-feedback {
	padding-top: 8px;
	padding-bottom: 8px; }

.not-available {
	width: 100%;
	height: 250px;
	display: flex;
	justify-content: center;
	align-items: center; }

.image-name {
	display: flex;
	min-height: 40px; }

.image {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4); }


.persona-name {
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 16px; }

.feedback {
	padding-left: 56px;
	padding-bottom: 9px;
	@include display-flex; }

.close-icon {
	position: absolute;
	right: -13px;
	top: -13px;
	width: 28px;
	height: 28px;
	z-index: 1; }

.user-interview-header-border {
	width: 100%;
	height: 5px; }

.new-text-container {
	@include display-flex-center-mixin;
	width: 42px;
	height: 22px;
	margin-left: 5px; }
