@import '~styles/mixins.sass';

.recommendation-container {
	width: 100%;
	cursor: pointer;
	position: relative;
	min-height: 50px;
	height: 100%; }

.single-recommendation {
	width: 100%;
	height: 100%;
	// @include display-flex-center-mixin
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	box-sizing: border-box;
	padding: 5px 20px;
	min-height: 40px; }

.rec-image {
	width: 24px;
	height: 24px;
	// margin-top: 5px
	border-radius: 50%;
	overflow: hidden;
	@include display-flex-center-mixin;
	flex-direction: column; }

.rec-name {
	margin-top: 5px; }


.rec-content {
	box-sizing: border-box;
	padding: 0 7px;
	width: calc(100% - 32px); }

.rec-talk-image {
	width: 12px;
	height: 10px; }

.all-recs {
	position: absolute;
	width: 100%;
	z-index: 20;
	background: white;
	display: flex; }
