@import '~styles/mixins.sass';

.header-container {
	@include display-flex-center-mixin;
	width: 100%;
	height: 54px;
	min-width: 1280px;
	position: absolute;
	top: 8px;
	left: 0;
	@extend %row; }

.header-content {
	@include display-flex-center-mixin;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	justify-content: space-between; }

.phases-container {
	width: 55%;
	height: 50px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	z-index: 20;
	box-sizing: border-box;
	padding-right: 2.5px;
	padding-left: 5px; }

.metrics-container {
	width: 19%;
	height: 50px;
	z-index: 20; }

.team-container {
	width: 24%;
	height: 50px;
	z-index: 20; }

.each-header-phase {
	height: 50px;
	@include display-flex-center-mixin;
	// cursor: pointer
	box-sizing: border-box;
	padding: 0 0px; }

.each-selected-header-phase {
	height: 54px;
	@include display-flex-center-mixin;
	// cursor: pointer
	padding: 0 0px; }

.phase-name {
	padding-right: 5px; }

.phase-arrow {
	width: 8px;
	height: 8px; }

.each-header-phase-execute {
	@include display-flex-center-mixin;
	// cursor: pointer
	box-sizing: border-box;
	padding: 0 5px;
	text-align: center;
	align-self: center; }

.each-selected-header-phase-execute {
	@include display-flex-center-mixin;
	// cursor: pointer
	padding: 0 5px;
	text-align: center;
	align-self: center; }

.budget-time-container {
	width: 100%;
	height: 100%;
	display: flex;
	// align-items: center
	// justify-content: space-around
	box-sizing: border-box;
 }	// padding: 0 16px

.budget-container {
	@include display-flex-center-mixin;
	position: relative;
	width: 50%;
	height: 100%;
	padding-left: 10px;
	box-sizing: border-box; }

.budget-image {
	width: 24px;
	height: 21px; }

.budget-value {
	padding-left: 8px;
	position: relative; }

.budget-value-change {
	position: absolute;
	bottom: 10px;
	left: 40%;
	padding: 3px 5px;
	border-radius: 20px; }

.timer-container {
	@include display-flex-center-mixin; }

.timer-image {
	width: 20px;
	height: 24px; }

.timer-value {
	padding-left: 8px; }

.team-events-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box; }

.team-content-container {
	@include display-flex-center-mixin; }

.team-label {
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	width: 35px;
	height: 35px;
	text-align: center; }

.event-container {
	@include display-flex-center-mixin;
	height: 100%;
	position: relative;
	margin-left: -20px; }

.event-content {
	@include display-flex-center-mixin;
	width: 57px;
	height: calc(100% - 6px); }

.event-image {
	width: 24px;
	height: 24px; }

.team-members {
	@include display-flex-center-mixin;
	padding: 0 0 0 5px; }

.tm-container {
	@include display-flex-center-mixin;
	box-sizing: border-box;
	padding: 0 5px; }

.tm-image {
	@include display-flex-center-mixin;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	box-sizing: border-box;
	overflow: hidden; }

.tm-details {
	padding-left: 4px;
	height: 100%;
	display: {}
	width: 60px; }

.tm-name {
	padding-bottom: 2px; }

.tm-role {
	padding-bottom: 2px; }

.budget-alert-container {
	position: relative;
	width: 100%;
	height: 340px;
	background: white;
	box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.16);
	margin-top: 20px;
	border-radius: 5px; }

.budget-alert-background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end; }

.background-image-container {
	width: 224px;
	height: 106px; }

.budget-alert-content {
	position: absolute;
	width: 100%;
	z-index: 2; }

.budget-text-firsttime-message-container {
	top: 48px;
	left: calc(50% - 249px);
	width: 497px;
	min-height: 340px;
	position: absolute;
	z-index: 10; }

.what-budget-arrow {
	width: 0;
	height: 0;
	position: absolute;
	top: 5px;
	left: calc(50% - 10px);
	z-index: 3; }

.what-budget-first-content {
	padding: 20px 30px; }

.what-budget-first-content-text {
	padding-bottom: 20px;
	text-align: left; }

.budget-buttons-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 40px; }

.budget-alert-heading {
	padding-bottom: 10px; }

.budget-alert-desc {
	padding-bottom: 30px; }

.budget-values-container {
	padding-bottom: 40px;
	width: 100%;
	box-sizing: border-box; }

.budget-values {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 10px; }

.budget-value-content {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column; }

.values-separator {
	width: 2px;
	height: 40px;
	margin: 0 50px; }

.budget-progress-bar-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box; }

.budget-progress-bar-back {
	width: 327px;
	height: 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 0 2px; }

.budget-progress-bar-front {
	height: 6px;
	border-radius: 4px; }

.value-heading {
	padding-bottom: 5px; }

.value-number {
	padding-bottom: 2px; }
