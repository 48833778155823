@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.question-chat-box-container {
    @include display-flex;
    width: 50%;
    min-height: 75px;
    height: fit-content;
    margin-bottom: 7px; }

.question-chat-box-content {
    width: 98%; }

.question-content-section-container {
    @extend %box-sizing;
    @extend %width-100;
    @include display-flex; }

.question-content-section {
    @extend %box-sizing;
    width: 85%;
    padding: 12px 0 6px 19px; }

.question-icon-section-container {
    @extend %box-sizing;
    @include display-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    height: 100%;
    width: 15%;
    z-index: 1; }

.question-icon-container {
    @include display-flex-center-mixin;
    flex-direction: column;
    text-transform: uppercase; }

.question-icon-section {
    width: 26px;
    height: 26px; }

.question-tick-section {
    width: 17px;
    height: 17px; }

.question-box-arrow-container {
    @include display-flex;
    align-items: flex-end;
    justify-content: flex-end;
    // position: relative
    width: 2%;
    min-height: 75px; }

.question-box-arrow-container-start {
    @include display-flex;
    align-items: flex-end;
    justify-content: flex-start;
    // position: relative
    width: 2%;
    min-height: 75px; }

.row-reverse {
    flex-direction: row-reverse; }

.question-chat-box-arrow {
    width: 0;
    height: 0;
    position: relative;
    // bottom: 0
 }    // left: -13px

.question-chat-box-arrow-left {
    width: 0;
    height: 0;
    // position: absolute
    // bottom: 0
 }    // left: 4px

.recommendation-container {
    @extend %width-100; }

.chat-suggestions-container {
    @extend %width-100;
    padding: 0 10px;
    box-sizing: border-box; }
