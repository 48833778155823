@import '~styles/mixins.sass';

.interview {
	width: 100%; }

.title {
	margin: 0px;
	height: 22px;
	padding-top: 4px;
	padding-bottom: 3px;
	@include display-flex;
	align-items: center; }

.arrow-title-container {
	cursor: pointer;
	width: fit-content;
	@include display-flex;
	align-items: center; }

.down-arrow {
	margin-right: 5px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent; }

.side-arrow {
	margin-right: 5px;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent; }

.content {
	margin: 0px;
	padding-bottom: 8px;
	padding-left: 15px; }

.show-transcript-message {
	@include display-flex;
	justify-content: flex-end;
	text-transform: uppercase;
	align-items: center; }

.video-component {
	@include display-flex-center-mixin;
	width: 100%;
	height: 280px; }

.video {
	width: 490px;
	height: 100%; }
