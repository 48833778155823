@import '~styles/mixins.sass';

.validation-popup-container {
	@include display-flex-center-mixin;
	width: 795px;
	height: 570px;
	flex-direction: column;
	justify-content: space-between;
	position: relative; }

.border-container {
	width: 100%;
	height: 10px;
	@include display-flex-center-mixin; }

.heading-container {
	width: 100%;
	@include display-flex-center-mixin;
	height: 65px;
	box-sizing: border-box;
	padding-top: 5px; }

.content-container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: calc(100% - 145px);
	flex-direction: column; }

.buttons-container {
	@include display-flex-center-mixin;
	width: 100%;
	padding: 20px 0 20px 0; }

.action-button {
	padding: 0 10px; }

.content-selected-heading-container {
	width: 100%;
	@include display-flex-center-mixin;
	padding-top: 10px;
	box-sizing: border-box; }

.content-selected-response-container {
	width: 100%;
	padding-top: 30px;
	box-sizing: border-box;
	min-height: 222px;
	display: flex;
	align-items: flex-start;
	justify-content: center; }

.single-heading-box {
	width: 120px; }

.single-heading {
	width: 100%;
	text-align: center;
	padding-bottom: 14px; }

.single-heading-img {
	display: block;
	margin: auto; }

.and-container {
	padding: 0 30px;
	@include display-flex-center-mixin; }

.response-card {
	width: 339px;
	min-height: 182px;
	height: 222px;
	position: relative;
	padding: 30px 24px 24px 24px;
	box-sizing: border-box;
	margin: 0 10px; }

.double-quotes-top {
	width: 22px;
	height: 14px;
	position: absolute;
	left: 25px;
	top: 22px; }

.double-quotes-bottom {
	width: 22px;
	height: 14px;
	position: absolute;
	right: 22px;
	bottom: 12px; }

.response-image {
	position: absolute;
	left: 20%;
	top: -25px;
	border-radius: 50%;
	overflow: hidden;
	width: 54px;
	height: 54px; }

.response-content {
	text-align: left; }

.response-persona-name {
	padding-left: 35px;
	padding-bottom: 10px; }

.close-button {
	position: absolute;
	right: -12px;
	top: -12px;
	width: 24px;
	height: 24px;
	z-index: 2; }

.button-help-text-exhausted {
	width: 60%;
	text-align: left;
	padding-bottom: 10px; }
