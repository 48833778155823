@import '~styles/mixins.sass';

.main-component {
    width: 100%;
    height: 100%;
    max-width: 38px;
    max-height: 38px;
    cursor: pointer;
    @include display-flex-center-mixin; }

