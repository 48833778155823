@import '~styles/mixins.sass';

.popup-container {
	width: 795px;
	// min-height: 650px
	// max-height: 700px
	position: relative; }

.border-container {
	width: 100%;
	height: 5px;
	@include display-flex-center-mixin; }

.heading-container {
	width: 100%;
	padding: 40px 55px 30px 55px;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	flex-direction: column;
	text-align: center; }

.close-button {
	position: absolute;
	right: -12px;
	top: -12px;
	width: 24px;
	height: 24px;
	z-index: 2; }
