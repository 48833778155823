@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.persona-list-container-center {
    @extend %width-100;
    @include display-flex-center-mixin;
    height: 100%;
    &:nth-last-child(1) {
        margin-right: 0; } }

.persona-list-container-start {
    @extend %width-100;
    @include display-flex-start;
    height: 100%; }

.individual-persona-list-container {
    @include display-flex-center-mixin;
    @extend %box-sizing;
    width: 85px;
    height: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-right: 10px;
    &:hover {
        transform: scale(1.1); } }

.no-margin {
    margin: 0; }

.persona-image-container {
    @extend %width-100;
    @include display-flex-center-mixin;
    position: relative; }

.image-container {
    border-radius: 50%;
    cursor: pointer;
    width: 60px;
    height: 60px;
    overflow: hidden;
    @include display-flex-center-mixin; }

.persona-image {
    // border-radius: 50%
    width: 100%;
    height: 100%; }

.persona-selected-tick {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 0;
    right: 7px; }

.persona-selected-tick-popup {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 0;
    right: 1px; }

.persona-name-container {
    @include display-flex-center-mixin;
    @extend %width-100;
    padding-top: 2px; }

.persona-level-cost-container {
    @include display-flex-center-mixin;
    @extend %box-sizing;
    @extend %width-100;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 15px; }

.persona-level {
    padding-bottom: 5px; }
