@import '~styles/mixins.sass';

.tags-list-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap; }

.each-tag {
	height: 20px;
	padding: 0 10px;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 5px;
	@include display-flex-center-mixin; }
