@import '~styles/mixins.sass';

.bar-container {
	width: 100%;
	height: 16px;
	// @include display-flex-center-mixin
	text-align: center;
	border-radius: 4px;
	margin-bottom: 10px;
	box-sizing: border-box;
	padding: 0 10px;
	max-width: 400px; }
