@import '~styles/mixins.sass';

.demographice-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap; }

.element {
	width: 50%;
	margin-top: 5px;
	margin-bottom: 5px; }
