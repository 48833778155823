@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.popup-container {
    @extend %row;
    @extend %box-sizing;
    @include display-flex-column;
    padding-bottom: 15px; }


.popup-header-container {
    @extend %width-100;
    @include display-flex;
    height: 72px; }

.popup-image-title-container {
    @include display-flex-start;
    width: 80%;
    height: 100%; }

.popup-image {
    width: 78px;
    height: 56px;
    padding-right: 26px; }

.popup-header-title {
    text-transform: uppercase;
    position: relative; }

.title-underline-container {
    position: absolute;
    width: 110%;
    height: 20px;
    bottom: -4px;
    left: 0px; }

.popup-close-container {
    @include display-flex-end;
    align-items: center;
    width: unset;
    height: 100%; }

.popup-questions-map-container {
    @extend %row;
    @include display-flex;
    width: 100%;
    height: 710px;
    padding: 0; }

.empathy-initial-map {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; }

.procedure-container {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 80%;
    justify-content: space-evenly;
    align-items: center; }

.empathy-gif-container {
    height: 550px;
    width: 950px; }

.empathy-intro-gif {
    width: 927px;
    height: 490px;
    margin-left: 20px;
    transform: translateX(150px); }

.popup-empathy-map-container {
    @extend %col-sm-5;
    @extend %col-md-5;
    @extend %col-lg-5;
    height: 100%; }

.close-icon {
    width: 30px;
    height: 30px;
    cursor: pointer; }

.step-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100px; }

.step1-container {
    position: absolute;
    left: 100px;
    width: 250px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    animation: fade1 ease 17s infinite; }

.step2-container {
    position: absolute;
    left: 100px;
    width: 250px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    animation: fade2 ease 17s infinite; }

.step3-container {
    position: absolute;
    left: 100px;
    width: 250px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    animation: fade3 ease 17s infinite; }

@keyframes fade1 {
    0% {
        opacity: 0; }
    4% {
        opacity: 1; }
    30% {
        opacity: 1; }
    32% {
        opacity: 0; }
    60% {
        opacity: 0; }
    100% {
        opacity: 0; } }

@keyframes fade2 {
    0% {
        opacity: 0; }
    32% {
        opacity: 0; }
    34% {
        opacity: 1; }
    68% {
        opacity: 1; }
    70% {
        opacity: 0; }
    100% {
        opacity: 0; } }

@keyframes fade3 {
    0% {
        opacity: 0; }
    70% {
        opacity: 0; }
    72% {
        opacity: 1; }
    97% {
        opacity: 1; }
    99% {
        opacity: 0; }
    100% {
        opacity: 0; } }
