@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.persona-popup-container {
    min-width: 900px;
    min-height: 600px;
    width: 75vw;
    height: 80vh;
    position: relative; }

.width-100-container {
    @extend %width-100;
    height: 100%;
    overflow-y: auto; }

.persona-list-container-popup {
    @extend %width-100;
    height: 74px; }

.linear-gradient-div {
    @extend %width-100;
    height: 2px; }

.persona-details-container {
    @extend %width-100;
    @extend %box-sizing;
    padding: 33px 0 33px 102px;
    height: calc(100% - 76px); }

.persona-name-title-container {
    @extend %width-100;
    padding-bottom: 23px;
    @include display-flex;
    justify-content: flex-start;
    flex-direction: column; }

.persona-name-title {
    padding-bottom: 4px; }

.horizontal-line {
    width: 21px;
    height: 1px; }

.persona-demographics-container {
    width: 30%;
    margin-right: 16px;
    padding: 6px 24px;
    box-sizing: border-box; }

.about-persona-demographics-container {
    width: 70%;
    box-sizing: border-box;
    padding-right: 102px; }

.bakground-image-container {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 238px;
    height: 162px; }

.close-icon {
    position: absolute;
    right: -13px;
    top: -13px;
    width: 28px;
    height: 28px;
    z-index: 1; }

.content {
    @include display-flex; }

.demographics-text {
    margin: 0px;
    width: 100%;
    padding-bottom: 16px; }

.interview {
    padding-bottom: 30px; }

.bio {
    padding-top: 0px; }

.experience {
    margin: 0px;
    padding-bottom: 8px; }

.interview-container {
    @include display-flex;
    flex-direction: column-reverse; }

.title {
	margin: 0px;
	height: 22px;
	padding-top: 4px;
	padding-bottom: 3px;
	@include display-flex;
	align-items: center; }

.arrow-title-container {
    cursor: pointer;
    width: fit-content;
    @include display-flex;
    padding-top: 8px;
    font-weight: 600; }

.down-arrow {
	margin-right: 5px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent; }

.side-arrow {
	margin-right: 5px;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent; }
