@import '~styles/mixins.sass';

.popup-container {
	width: 795px;
	// min-height: 650px
	// max-height: 700px
	position: relative; }

.border-container {
	width: 100%;
	height: 5px;
	@include display-flex-center-mixin; }

.heading-container {
	width: 100%;
	padding: 48px 55px 0 55px;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	flex-direction: column;
	text-align: center; }

.heading-main {
	padding-bottom: 4px; }

.heading-desc {
	padding-bottom: 25px; }

.metric-value {
	justify-content: space-between; }

.buttons-container {
	@include display-flex-center-mixin;
	width: 100%;
	padding: 15px 0;
	flex-direction: column; }

.buttons-content {
	@include display-flex-center-mixin;
	width: 100%; }

.action-button {
	padding: 0 10px; }

.shortlist-desc {
	padding-bottom: 10px; }

.close-button {
	position: absolute;
	right: -12px;
	top: -12px;
	width: 24px;
	height: 24px;
	z-index: 2; }
