@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.user-interview-popup-container {
    width: 840px;
    height: 530px;
    position: relative; }

.user-interview-header-border {
    @extend %width-100;
    height: 5px; }

.user-interview-content-container {
    @extend %width-100;
    @extend %box-sizing;
    height: calc(100% - 5px);
    padding: 27px 102px; }

.title-container {
    @extend %width-100;
    padding-bottom: 24px; }

.user-interview-title-container {
    @extend %width-100;
    padding-bottom: 12px; }

.user-interview-subtitle-container, .user-interview-instruction-persona-container {
    @extend %width-100; }

.instruction-message {
    @extend %width-100;
    padding-bottom: 10px; }

.instruction-message-note {
    padding-bottom: 20px; }

.user-interview-persona-list-container {
    width: 440px;
    // padding-bottom: 15px
    margin-bottom: 56px; }

.user-interview-loader-container {
    @extend %width-100;
    @extend %box-sizing;
    @include display-flex-start;
    flex-direction: column;
    padding-top: 68px;
    height: calc(100% - 76px); }

.check-icon-container {
    padding-bottom: 24px;
    @include display-flex-center-mixin; }

.user-interview-feedback-container {
    @extend %width-100;
    @include display-flex-center-mixin;
    padding-bottom: 10px; }

.bakground-image-container {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    width: 239px;
    height: 194px; }

.close-icon {
    position: absolute;
    right: -13px;
    top: -13px;
    width: 28px;
    height: 28px;
    z-index: 1; }

.action-tick-container {
    width: 64px;
    height: 64px; }
