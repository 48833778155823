@import '~styles/mixins.sass';

.popup-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 45;
	display: flex;
	align-items: center;
	justify-content: flex-end; }

.popup-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: flex-end; }

.popup-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
