@import '~styles/mixins.sass';

.popup-header-title {
	position: relative; }

.title-underline-container {
	position: absolute;
	width: 100%;
	max-width: 193px;
	height: 20px;
	bottom: -4px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: flex-start; }
