@import '~styles/mixins.sass';

.walkthrough-container {
	position: absolute;
	width: 160px;
	min-height: 70px; }

.walkthrough-container-bottom {
	position: absolute;
	width: 200px;
	min-height: 100px; }

.walkthrough-background-left {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
	top: -10px;
	left: 0;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start; }

.walkthrough-background-right {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 10px;
	left: 0;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end; }

.walkthrough-background-bottom {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start; }

.walkthrough-arrow-image-container {
	width: 38px;
	height: 36px; }

.walkthrough-arrow-image {
	width: 100%;
	height: 100%; }

.rotate-right {
	transform: rotateY(180deg);
	transform-origin: 50% 50%; }

.walkthrough-content-left {
	width: 100%;
	margin-top: 30px;
	padding: 0 10px 10px 15px;
	text-align: left;
	box-sizing: border-box; }

.walkthrough-content-right {
	width: 100%;
	margin-top: 45px;
	margin-left: 10px;
	padding: 0 15px 10px 10px;
	text-align: right;
	box-sizing: border-box; }

.rotate-bottom {
	transform: rotate(-90deg);
	transform-origin: 50% 50%; }

.walkthrough-content-bottom {
	width: calc(100% - 45px);
	margin-left: 45px;
	padding: 10px 15px 10px 0px;
	text-align: left;
	box-sizing: border-box;
	display: flex; }
