@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.popup-questions-content {
    @extend %width-100;
    @extend %box-sizing;
    height: 100%;
    position: relative; }

.empathy-map-title-container {
    @extend %width-100;
    @extend %box-sizing;
    @include display-flex-start;
    justify-content: space-between;
    padding: 0 15px;
    height: 33px; }

.empathy-map-title-flex {
    @include display-flex;
    align-items: center;
    width: 60%; }

.auto-fill-container {
    @include display-flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%; }

.about-container {
    @include display-flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%; }

.popup-header-title {
    position: relative; }

.info-icon-container {
    padding-right: 5px; }

.title-underline-container {
    width: 110%;
    position: absolute;
    height: 20px;
    bottom: -8px;
    left: 0px; }

.empathy-map-background {
    width: 100%;
    height: calc(100% - 33px);
    position: absolute;
    top: 33px;
    left: 0;
    z-index: 2; }

.empathy-quadrant-container {
    @extend %width-100;
    @include display-flex;
    position: relative;
    flex-wrap: wrap;
    height: calc(100% - 33px);
    z-index: 5; }

.empathy-quadrant {
    @extend %box-sizing;
    padding: 0 12px;
    width: 50%;
    height: 33.33%;
    overflow-y: auto;
    &:nth-child(2n+1) {
        border-right-width: 1px;
        border-right-style: dashed; }
    &:nth-last-child(2) {
        border-bottom-width: 0; }
    &:nth-last-child(1) {
        border-bottom-width: 0; } }

.empathy-quadrant-title {
    @extend %width-100;
    @include display-flex-center-mixin;
    padding-bottom: 10px; }

.list-responses-container {
    @extend %width-100;
    // @include display-flex
 }    // flex-direction: column

.list-container {
    @extend %width-100;
    @include display-flex; }

.list-disc {
    width: 2%;
    height: 6px;
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 50%; }

.auto-fill-content {
    width: fit-content;
    cursor: pointer;
    text-transform: uppercase;
    position: relative; }

.about-content {
    padding-left: 14px;
    text-decoration: underline;
    width: fit-content;
    cursor: pointer; }

.response-content {
    width: 98%; }

.emapthy-initial-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box; }

.empathy-map-initial-content {
    width: 100%;
    height: 92%;
    overflow: auto;
    padding: 16px 27px;
    box-sizing: border-box; }

.empathy-map-filled-button {
    @include display-flex-center-mixin;
    width: 100%;
    height: 8%;
    box-sizing: border-box; }

.title-container-initial {
    width: 100%;
    padding-bottom: 12px; }

.emapthy-initial-image-container {
    width: 100%;
    @include display-flex-center-mixin;
    padding-bottom: 22px; }

.empathy-map-intial-image {
    width: 178px;
    height: 133px;
    position: relative; }

.empathy-initial-quadrants-intro {
    width: 100%;
    padding-top: 5px;
    @include display-flex;
    flex-wrap: wrap; }

.question-container-initial {
    width: 50%;
    padding-bottom: 16px;
    padding-right: 12px;
    box-sizing: border-box; }

.empathy-quad-title {
    padding-right: 5px;
    text-transform: uppercase; }
