@import '~styles/mixins.sass';

.notes-main-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 50; }

.notes-container {
	width: 350px;
	min-height: 25px;
	@include display-flex-center-mixin; }

.notes-content {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	@include display-flex-center-mixin;
	flex-direction: column;
	box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3); }

.notes-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.notes-header {
	width: 100%;
	height: 25px;
	@include display-flex-center-mixin;
	box-sizing: border-box;
	padding: 0 15px;
	background-color: #c6eaff;
	cursor: pointer; }

.notes-header-text {
	width: 85%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.notes-save-button-container {
	width: 100%;
	height: 30px;
	@include display-flex-center-mixin; }

.save-button {
	cursor: pointer;
	text-transform: uppercase; }

.notes-header-arrow-container {
	width: 15%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end; }

.notes-header-arrow {
	cursor: pointer;
	width: 8px;
	height: 6px;
	@include display-flex-center-mixin; }

.notes-quill-content {
	width: 100%;
	height: 340px;
	overflow: auto;
	background-color: #f1faff;
	font-weight: 400; }

.no-drag {
	width: 100%; }

.bottom-div {
	width: 100%;
	height: 2px; }
