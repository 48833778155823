@import '~styles/mixins.sass';

.popup-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 41;
	@include display-flex-center-mixin; }

.popup-content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	@include display-flex-center-mixin; }

.popup-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }
