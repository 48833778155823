@import '~styles/mixins.sass';

.detail {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	min-height: 50px; }

.title {
	margin: 0px; }

.value {
	margin: 0px; }
