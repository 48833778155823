@import '~styles/mixins.sass';

.dfv-container {
	@include display-flex-center-mixin; }

.single-container {
	padding: 0 15px;
	@include display-flex-center-mixin;
	flex-direction: column; }

.single-heading {
	padding-bottom: 4px; }

.single-value {
	@include display-flex-center-mixin;
	padding-bottom: 1px; }

