@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.timer-container {
	@include display-flex-center-mixin;
	position: relative;
	position: relative;
	width: 50%;
	height: 100%;
	padding-right: 10px;
	box-sizing: border-box; }

.timer-image {
	width: 20px;
	height: 24px; }

.timer-value {
	padding-left: 8px; }

.budget-alert-container {
	position: relative;
	width: 100%;
	height: 340px;
	background: white;
	box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.16);
	margin-top: 20px;
	border-radius: 5px; }

.budget-alert-background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end; }

.background-image-container {
	width: 128px;
	height: 96px; }

.budget-alert-content {
	position: absolute;
	width: 100%;
	z-index: 2; }

.budget-text-firsttime-message-container {
	top: 48px;
	left: calc(50% - 249px);
	width: 497px;
	min-height: 340px;
	position: absolute;
	z-index: 10; }

.what-budget-arrow {
	width: 0;
	height: 0;
	position: absolute;
	top: 5px;
	left: calc(50% - 10px);
	z-index: 3; }

.what-budget-first-content {
	padding: 20px 30px; }

.what-budget-first-content-text {
	padding-bottom: 20px;
	text-align: left; }

.budget-buttons-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 40px; }

.budget-alert-heading {
	padding-bottom: 10px; }

.budget-alert-desc {
	padding-bottom: 30px; }

.budget-values-container {
	padding-bottom: 40px;
	width: 100%;
	box-sizing: border-box; }

.budget-values {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 10px; }

.budget-value-content {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column; }

.values-separator {
	width: 2px;
	height: 40px;
	margin: 0 50px; }

.budget-progress-bar-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box; }

.budget-progress-bar-back {
	width: 327px;
	height: 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 0 2px; }

.budget-progress-bar-front {
	height: 6px;
	border-radius: 4px; }

.value-heading {
	padding-bottom: 5px; }

.value-number {
	padding-bottom: 2px; }
