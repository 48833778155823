@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.time-up-component {
	@extend %width-100;
	height: 100%;
	@include display-flex;
	align-items: flex-end;
	box-sizing: border-box;
 }	// padding-bottom: 41px

.container {
	width: 100%;
	height: 54px;
	border-radius: 4px;
	z-index: 1; }

.wrapper {
	@include display-flex-center-mixin;
	height: 54px;
	padding-right: 28px;
	box-sizing: border-box; }

.text {
	margin-right: 30px;
	width: 60%;
	@include display-flex-center-mixin; }

.feedback-button {
	margin-right: 10px; }

