@import '~styles/mixins.sass';

%width-100 {
	width: 100%; }

%box-sizing {
	box-sizing: border-box; }

.followup-questions-container {
    @extend %width-100;
    height: 100%; }

.followup-questions-close-container {
    @include display-flex-end;
    @extend %width-100;
    height: 5%; }

.popup-chat-container-text {
    @extend %width-100;
    height: 51%;
    overflow-y: auto; }

.popup-ask-questions-container {
    @extend %width-100;
    @extend %box-sizing;
    height: 100%;
    padding: 18px 0 12px 12px; }

.ask-questions-title-container {
    @include display-flex;
    height: 61px; }

.ask-questions-title-cost {
    @include display-flex;
    @extend %box-sizing;
    padding-top: 5px;
    padding-right: 12px;
    width: 50%;
    height: 100%; }

.display-to-start {
    @include display-flex;
    justify-content: flex-start;
    align-items: flex-start; }

.display-to-end {
    @include display-flex;
    justify-content: flex-end;
    align-items: flex-start; }

.ask-questions-chatbox-container {
    @extend %width-100;
    @include display-flex-space-between;
    flex-wrap: wrap;
    height: calc(100% - 61px);
    overflow-y: auto; }

.followup-questions-close {
    @include display-flex-center-mixin;
    min-width: 90px;
    height: 100%;
    cursor: pointer;
    text-transform: uppercase; }

.followup-questions-content-container {
    @extend %width-100;
    height: 95%; }

.popup-empathy-map-container {
    @extend %col-sm-5;
    @extend %col-md-5;
    @extend %col-lg-5;
    height: 100%; }

.ask-question-container {
    padding-top: 10px; }

.question-response-container {
    @extend %width-100;
    margin-bottom: 10px; }

.align-flex-right {
    @include display-flex-end; }

.align-flex-right-column {
    @include display-flex;
    flex-direction: column;
    align-items: flex-end; }

.align-flex-left {
    @include display-flex-start;
    align-items: flex-start; }

.cursor-pointer {
    cursor: pointer; }
